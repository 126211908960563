import React from 'react'
import HeaderCommon from './HeaderCommon'
import Team from '../assests/Team.svg'
import DP from '../assests/GOwthaman DP.jpeg'

const Aboutus = () => {
  return (
    <div className="about-page">
      <div className='sticky-top'>
        <HeaderCommon/>
      </div>
      {/* Hero Section */}
      <section className="text-center d-flex justify-content-center align-items-center">
        <div className="container">
          <h1 className="display-4 fw-bold mt-5 mb-0 text-primary lilita-one-regular">ABOUT US</h1>
          <div className='d-flex flex-wrap justify-content-center align-items-center'>
            <img className='col-11 col-sm-10 col-md-8 col-lg-3 col-xl-4 rounded-pill' src={Team} alt='Team'/>
            <div className='m-2 col-12 col-sm-11 col-md-11 col-lg-7'>
              <p className='m-0 fs-5'>Welcome to <span className='fw-bold text-primary'>ONE POINT RESEARCH</span>, your trusted partner in Coimbatore for transforming business challenges into opportunities. We specialize in delivering customized, high-quality solutions across various domains, including data extraction, web scraping, data mining, data scraping, market research, data entry, UI/UX design, and digital marketing.<br/>
                Our expert team combines local insights with global best practices to craft strategies tailored to your unique needs, ensuring measurable results. At One Point Solution, we go beyond being just a service provider - we are committed to your success through precision and innovation.</p>
            </div>
          </div>
        </div>
      </section>



      {/* <section className="mission-statement py-5 text-center bg-light">
        <div className="container">
          <h2 className="display-4 text-primary"></h2>
          <p className="lead">At OnePoint Research, we are committed to delivering high-quality solutions tailored to meet our clients' needs. We leverage our expertise and innovative approach to help businesses thrive in a competitive landscape.</p>
        </div>
      </section> */}
      


      
      <section className="mission-statement py-3 text-center d-flex flex-wrap justify-content-center align-items-shrink">
        {/* Mission Statement */}
        <div className="container col-11 col-sm-11 col-md-10 col-lg-5 px-2 py-5 m-3 rounded" style={{background: '#7561a2'}}>
          <h2 className="display-4 text-light fs-2 fw-bold lilita-one-regular"><i className='bi bi-bar-chart text-light'></i> OUR MISSION</h2>
          <p className="lead text-light mx-3" style={{textAlign: 'justify'}}>At One Point Solution, our mission is to be the catalyst for business transformation through advanced, data-driven solutions. We are dedicated to providing bespoke services that include data extraction, web scraping, data mining, and comprehensive market research. Our focus extends to services including information about the target audience, competitors, and the industry as a whole. By integrating these insights with strategic digital marketing and innovative UI/UX design, we help our clients not only meet but exceed their business objectives. Our commitment is to combine in-depth local knowledge with global best practices to deliver high-quality, tailored solutions that drive sustainable growth, optimize operations, and create long-lasting success.</p>
        </div>

        {/* Vision Statement */}
        <div className="container col-11 col-sm-11 col-md-10 col-lg-5 px-2 py-5 m-3 rounded" style={{background: '#f8ebff'}}>
          <h2 className="display-4 text-primary fs-2 fw-bold lilita-one-regular"><i className='bi bi-bullseye text-secondary'></i> OUR VISION</h2>
          <p className="lead text-dark mx-3" style={{textAlign: 'justify'}}>Our vision is to set the standard for excellence in the realm of data research and market intelligence, establishing ourselves as the premier global partner for businesses seeking to leverage data for strategic advantage. We envision a future where One Point Solution is synonymous with groundbreaking data solutions and market insights, empowering organizations worldwide to harness precise, actionable intelligence. Through our expertise in data extraction, web scraping, data mining, and market research, we aim to enable businesses to make informed, strategic decisions. We strive to be the trusted advisor that businesses turn to for innovative, data-driven strategies that transform challenges into opportunities, foster competitive advantage, and drive unprecedented levels of success and growth.</p>
        </div>
      </section>

      {/* Team Section */}
      <section className="team-section py-3 ">
        <div className="container">
          <h2 className="text-center text-dark mb-4 lilita-one-regular">MEET OUR TEAM</h2>
          <div className="row d-flex justify-content-center">
            <div className="col-11 col-sm-11 col-md-10 col-lg-8">
              <div className="team-card text-center py-4 rounded-pill shadow-sm d-flex flex-wrap justify-content-center align-items-center">
                <img src={DP} alt="Team Member 3" className="rounded-circle col-11 col-sm-10 col-md-4 col-lg-3"/>
                <div className='col-11 col-sm-11 col-md-7 col-lg-8 p-3'>
                  <h3 className="mb-2 text-primary">Gowthaman Sethukumar</h3>
                  <p className=" m-0 text-muted">FOUNDER & CEO</p>
                  <p className='m-0' onClick={()=>window.open("mailto:gowthaman@onepointresearch.com")} role='button' style={{boxSizing: 'border-box'}}><span className='text-primary fw-bold'>Email:</span> gowthaman@onepointresearch.com</p>
                  <p className='my-2'>Leads the team with precision and analytical skills, delivering deep insights into market trends.</p>
                  <button className='btn btn-outline-primary' onClick={()=>window.open("https://www.linkedin.com/in/gowthaman-sethukumar-3b3783288?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app","_blank")}>Connect</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Aboutus
