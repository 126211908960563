import React from 'react'
import HeaderCommon from './HeaderCommon'

const Transcription = () => {

const TranscriptionServices = [
    {
        title: 'General Transcription Services',
        desc: `General transcription covers a broad range of transcription tasks that do not require specialized knowledge. This may include meetings, interviews, podcasts, webinars, and more.`
    },
    {
        title: 'Medical Transcription Services',
        desc: `Medical transcription involves transcribing doctor’s notes, patient histories, medical reports, and clinical summaries. It requires knowledge of medical terminology and compliance with healthcare regulations like HIPAA.`
    },
    {
        title: 'Legal Transcription Services',
        desc: `Legal transcription involves converting audio recordings of court proceedings, depositions, witness interviews, and legal dictations into text. This type of transcription demands a strong understanding of legal terminology.`
    },
    {
        title: 'Academic Transcription Services',
        desc: `Academic transcription includes converting audio from lectures, seminars, research interviews, and focus groups into text. It is widely used by students, researchers, and academic professionals.`
    },
    {
        title: 'Business Transcription Services',
        desc: `Business transcription focuses on corporate needs, such as transcribing meetings, conferences, presentations, interviews, and webinars. It ensures that important business communications are recorded accurately.`
    },
    {
        title: 'Financial Transcription Services',
        desc: `Financial transcription includes transcribing earnings calls, investor meetings, annual reports, and financial presentations. It requires a good grasp of financial terminology and data accuracy.`
    },
    {
        title: 'Media Transcription Services',
        desc: `Media transcription involves converting audio or video content into text for TV shows, films, interviews, podcasts, and radio broadcasts. It’s vital for scriptwriting, subtitling, and closed captioning.`
    },
    {
        title: 'Technical Transcription Services',
        desc: `Technical transcription deals with highly specialized fields, such as IT, engineering, or science, where industry-specific jargon must be accurately captured in written form.`
    },
    {
        title: 'Verbatim Transcription Services',
        desc: `Verbatim transcription captures every word spoken, including filler words, false starts, and background sounds, making it suitable for legal purposes, interviews, and research studies.`
    },
    {
        title: 'Intelligent Verbatim Transcription',
        desc: `Intelligent verbatim transcription excludes non-essential speech elements like filler words (e.g., "um," "uh"), focusing on conveying the core message in a clear and concise manner.`
    },
    {
        title: 'Multilingual Transcription Services',
        desc: `Multilingual transcription involves transcribing audio in multiple languages or converting spoken content from one language to another in written form. It is useful for international businesses, media, and academia.`
    },
    {
        title: 'Captioning and Subtitling Services',
        desc: `Captioning and subtitling involve adding text to videos, ensuring accessibility for viewers, including those who are deaf or hard of hearing, or for content being viewed in multiple languages.`
    },
    {
        title: 'Audio Transcription Services',
        desc: `Audio transcription involves converting any form of audio, including recordings, voice memos, podcasts, or interviews, into written text. It caters to diverse industries from media to corporate.`
    },
    {
        title: 'Video Transcription Services',
        desc: `Video transcription converts spoken content from video files into text. It’s often used for webinars, interviews, movies, and training videos, and is essential for creating subtitles or scripts.`
    },
    {
        title: 'Real-Time Transcription Services',
        desc: `Real-time transcription (live transcription) provides immediate transcription of audio as it happens, commonly used in courtrooms, conferences, or live broadcasts for accessibility.`
    },
    {
        title: 'Interview Transcription Services',
        desc: `Convert interviews into readable text for market research, journalism, or academic purposes. Our outsourcing interview transcription services capture every detail efficiently.`
    }
];

    

  return (
    <div>
      <div className='sticky-top'>
        <HeaderCommon/>
      </div>
        <section className="d-flex justify-content-center align-items-center">
            <div className="container">
                <h2 className="text-center display-4 fw-bold mt-5 mb-0 text-primary lilita-one-regular">Transcription Services</h2>
                <p className="text-center my-3 lead fs-5">
                    We provide professional transcription services that convert your audio and video content into accurate, readable text. From business meetings to legal proceedings, our team ensures clarity and precision, tailored to your specific needs. Enhance accessibility, streamline workflows, and preserve important information with our reliable transcription solutions. We offer;
                </p>

                <div className='d-flex flex-wrap justify-content-center'>
                {
                    TranscriptionServices.map((mr, i)=>{
                        return(
                            <dl className='col-11 col-sm-10 col-md-5 col-lg-4 col-xl-3 m-2 py-2 px-4' key={i} style={{background: `${i%2===0?'#f0f0fd':'#e3e3fc'}`}}>
                                <dt className='text-center my-3 fs-5 lilita-one-regular text-primary'>{i+1}) {mr.title.toUpperCase()}</dt>
                                <dd style={{textAlign:'justify'}}>{mr.desc}</dd>
                            </dl>
                        )
                    })
                }
                </div>
            </div>
        </section>

    </div>
  )
}

export default Transcription
