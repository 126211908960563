import React from 'react'
import HeaderCommon from './HeaderCommon'

const DataEntry = () => {

    const dataEntry = [
        {
            title: 'Directory Services',
            desc: 'Efficiently manage and update business directories with accurate and up-to-date information, enhancing visibility and accessibility for potential clients.'
        },
        {
            title: 'Image Data Entry Services',
            desc: 'Transform visual data into structured formats with precision, ensuring easy access, analysis, and utilization for your business needs.'
        },
        {
            title: 'Online Data Entry Services',
            desc: 'Streamline your operations by entering data directly into your online systems, ensuring real-time accuracy and accessibility.'
        },
        {
            title: 'Offline Data Entry Services',
            desc: 'Safeguard your offline data with meticulous entry services that ensure accuracy, security, and seamless integration into your business processes.'
        },
        {
            title: 'Medical Data Entry Services',
            desc: 'Ensure compliance and accuracy in medical records management with specialized data entry services tailored for healthcare professionals.'
        },
        {
            title: 'Magento Data Entry Services',
            desc: 'Enhance your e-commerce platform with accurate and comprehensive product data entry, optimizing your Magento store for better customer experience and sales.'
        },
        {
            title: 'Invoice Data Entry Services',
            desc: 'Simplify your financial management by ensuring accurate and timely entry of invoices, reducing errors and streamlining your billing process.'
        },
        {
            title: 'Product Data Entry Services',
            desc: 'Improve your inventory management with precise product data entry, ensuring up-to-date information for better customer satisfaction and sales performance.'
        },
        {
            title: 'Legal Documents Data Entry Services',
            desc: 'Maintain the integrity of legal documents with accurate data entry services, ensuring compliance and accessibility for legal professionals.'
        },
        {
            title: 'Catalogs Data Entry Services',
            desc: 'Keep your product catalogs organized and up-to-date with expert data entry services, enhancing customer experience and operational efficiency.'
        },
        {
            title: 'Data Entry into Databases',
            desc: 'Ensure your databases are populated with accurate and well-organized data, improving the efficiency of your business operations.'
        },
        {
            title: 'Data Entry from Paper Documents',
            desc: 'Digitize your paper documents with precision, ensuring accurate data entry and easy access to vital information.'
        },
        {
            title: 'Multi-Language Data Entry Services',
            desc: 'Expand your global reach with data entry services in multiple languages, ensuring accuracy and cultural relevance for diverse markets.'
        }
    ];
    

  return (
    <div>
      <div className='sticky-top'>
        <HeaderCommon/>
      </div>
        <section className="d-flex justify-content-center align-items-center">
            <div className="container">
                <h2 className="text-center display-4 fw-bold mt-5 mb-0 text-primary lilita-one-regular">Data Entry</h2>
                <p className="text-center my-3 lead fs-5">Accuracy and efficiency are our priorities. We handle data entry with meticulous attention to detail, ensuring your information is organized, accurate, and ready for strategic use. We do;</p>
                <div className='d-flex flex-wrap justify-content-center'>
                {
                    dataEntry.map((mr, i)=>{
                        return(
                            <dl className='col-11 col-sm-10 col-md-5 col-lg-4 col-xl-3 m-2 py-2 px-4' key={i} style={{background: `${i%2===0?'#f0f0fd':'#e3e3fc'}`}}>
                                <dt className='text-center my-3 fs-5 lilita-one-regular text-primary'>{i+1}) {mr.title.toUpperCase()}</dt>
                                <dd style={{textAlign:'justify'}}>{mr.desc}</dd>
                            </dl>
                        )
                    })
                }
                </div>
            </div>
        </section>
    </div>
  )
}

export default DataEntry
