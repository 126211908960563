import React from 'react'
import { Link } from 'react-router-dom'
import HeaderTransparent from './HeaderTransparent'
import Teams from '../assests/undraw_stand_out_-1-oag.svg'
import Carousel from './Carousel'
import Featured from './Featured'

const Home = () => {



  return (
    <div>
      <div className='sticky-top'>
        <HeaderTransparent/>
      </div>
      
      <>
      <Carousel/>
      </>

      {/* About Section */}
      <section className="about-section py-5 text-center d-flex justify-content-center align-items-center">
        <div className="container">
          <h2 className='fs-2 text-primary lilita-one-regular'>WHO WE ARE?</h2>
          <div className='d-flex flex-wrap justify-content-center'>
            <img className='rounded-circle border border-secondary border-2 m-3 col-10 col-sm-10 col-md-4 col-lg-3' src={Teams} style={{width:'200px', height: '200px'}} alt='Team'/>
            <blockquote className="blockquote col-11 col-sm-11 col-md-6 col-lg-8">
              <p className='fs-5 text-secondary' style={{textAlign: 'justify'}}>Empowering Your Business with Data-Driven Research and Market Insights
                Your trusted partner in Coimbatore, transforming business challenges into opportunities with tailored, high-quality solutions. We specialize in Data extraction, Web scraping, Data mining, Market research, Data entry, UI/UX design, and Digital marketing.
                With a blend of local insights and global best practices, our expert team is dedicated to driving your success through precision, innovation, and measurable results.</p>
            </blockquote>
          </div>
          <button className='btn btn-outline-primary px-3 fs-5' onClick={()=>window.location.href="/about"}>Know more</button>
        </div>
      </section>


      {/* Services Section */}
      <section className="services-section py-2 bg-light">
        <div className="container">
          <h2 className='fs-2 text-center mt-3 text-primary lilita-one-regular'>FEATURED SERVICES</h2>
          <Featured/>
        </div>
      </section>


      {/* Contact Section */}
      <section className="contact-section py-5 bg-light text-center">
        <div className="container d-flex gap-2 justify-content-center align-items-center flex-wrap">
          <h2 className="text-primary">Get In Touch</h2>
          <Link to="/contact" className="btn btn-primary fs-4 px-4 mx-3">Contact Us</Link>
        </div>
      </section>
    </div>
  )
}

export default Home
