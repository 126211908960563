import React from 'react'
import MRImg from '../assests/WEB OR MR.png'
import DEImg from '../assests/DATA ENTRY.png'
import DMImg from '../assests/DM.png'
import UIUXImg from '../assests/UI UX.png'
import TImg from '../assests/TRANSCRPTION.png'
import DcImg from '../assests/DATA TRANSFER.png'
import DpImg from '../assests/DATA PROCESSING.png'

const Featured = () => {

    const Services = [
        {
          title: 'Web/Market Research',
          desc: `Outsource your web/market research for high-quality, actionable business insights.`,
          icon: 'bi-bar-chart-steps',
          img: MRImg,
          // point: ['Firmographic Data','Demographic Data','Chronographic Data','Customer Data'],
          point: ['Exploratory Research','Descriptive Research','Causal Research','Predictive Research'],
          link: '/market-research'
        },
        {
          title: 'Data Entry',
          desc: `Professional data entry outsourcing solutions for accurate, fast, and cost-effective results.`,
          icon: 'bi-kanban',
          img: DEImg,
          point: ['Product Data Entry Services','Invoice Data Entry Services','Medical Data Entry Services','Data Entry from Paper Documents'],
          link: '/data-entry'
        },
        {
          title: 'Digital Marketing',
          desc: `Grow your brand with tailored digital marketing outsourcing solutions and expertise.`,
          icon: 'bi-bar-chart-fill',
          img: DMImg,
          point: ['Website Design','SEO, Google Ads','Social Media Marketing', 'Email Marketing Services'],
          link: '/digital-marketing'
        },
        {
          title: 'UI/ UX Design',
          desc: `Top-tier outsourcing for UI/UX design and development services globally.`,
          icon: 'bi-palette',
          img: UIUXImg,
          point: ['Mobile Applications', 'Website Design','Poster/Banner Design','Landing Pages'],
          link: '/ui-ux-desinging'
        },
        {
          title: 'Transcription Services',
          desc: `High-quality transcription outsourcing services: accurate, prompt, and reliable results.`,
          icon: 'bi-palette',
          img: TImg,
          point: ['General Transcription Services', 'Medical Transcription Services','Legal Transcription Services','Academic Transcription Services'],
          link: '/transcription'
        },
        {
          title: 'Data Conversion Services',
          desc: `Expert outsourcing data conversion services for efficient, accurate data processing`,
          icon: 'bi-palette',
          img: DcImg,
          point: ['PDF to Word Conversion Services', 'Image to Text (OCR) Conversion Services','Excel to Database Conversion Services','HTML to XML/JSON Conversion Services'],
          link: '/data-conversion'
        },
        {
          title: 'Data Processing Services',
          desc: `Efficient outsourcing for data processing: accuracy, reliability, and cost-effective solutions.`,
          icon: 'bi-palette',
          img: DpImg,
          point: ['Data Cleansing and Enrichment Services', 'Data Mining Outsourcing','Data Conversion Outsourcing','Database Management Services'],
          link: '/data-processing'
        }
    ]

  return (
    <div className="row d-flex justify-content-center">  
        {
        Services.map((service, i)=>{
            return(
                
            <div class="text-center feature-card m-2 col-11 col-md-10 col-lg-4 col-xl-3" key={i} onClick={()=>window.location.href=`${service.link}`}>
                <div class="icon-wrapper p-3">
                    <img className='w-100' src={service.img} alt={service.title} />
                </div>
                <h3 className='fs-5 my-2'>{service.title.toUpperCase()}</h3>
                <p>{service.desc}</p>
            </div>

            )
        })
        }
      
    </div>
  )
}

export default Featured
