import React from 'react'
import HeaderCommon from './HeaderCommon'

const DataConversion = () => {

    const DataConversionServices = [
        {
          title: "PDF to Word Conversion Services",
          desc: "Converting PDF files into editable Word documents while retaining the formatting and structure. This service is crucial for businesses that need to modify content or extract information from static PDF files."
        },
        {
          title: "Image to Text (OCR) Conversion Services",
          desc: "Using Optical Character Recognition (OCR) technology to convert scanned images or PDFs into editable text files. This is beneficial for organizations dealing with large volumes of printed or handwritten documents."
        },
        {
          title: "Excel to Database Conversion Services",
          desc: "Transferring data from Excel spreadsheets to structured databases (SQL, MySQL, etc.). This helps businesses manage large datasets efficiently and retrieve information quickly."
        },
        {
          title: "HTML to XML/JSON Conversion Services",
          desc: "Converting HTML content into structured XML or JSON formats. This service is essential for businesses needing data to be in a more machine-readable and compatible format for web and application development."
        },
        {
          title: "Document Scanning and Conversion Services",
          desc: "Digitizing physical documents and converting them into electronic formats (PDF, Word, Excel). This service is ideal for companies looking to go paperless and manage their archives more efficiently."
        },
        {
          title: "CSV to Database Conversion Services",
          desc: "Converting CSV files into a database format for better organization and data analysis. This service is often used when businesses move from simple spreadsheets to more advanced data management systems."
        },
        {
          title: "Ebook Conversion Services",
          desc: "Converting printed or digital manuscripts into eBook formats such as ePub, Mobi, or PDF for various devices and platforms. This is widely used by publishers, authors, and content creators."
        },
        {
          title: "Legacy Data Conversion Services",
          desc: "Migrating data from outdated systems or formats (e.g., old databases, floppy disks) into modern platforms. This service helps organizations preserve historical data and integrate it into newer technologies."
        },
        {
          title: "XML/JSON to CSV Conversion Services",
          desc: "Transforming data from XML or JSON formats into CSV for easier handling in Excel or other spreadsheet tools. This is often required for businesses needing simplified, flat file formats for analysis."
        },
        {
          title: "Database to Cloud Conversion Services",
          desc: "Migrating on-premise databases to cloud platforms (e.g., AWS, Azure, Google Cloud) for enhanced scalability, security, and accessibility. This service is crucial for businesses transitioning to cloud-based solutions."
        },
        {
          title: "Audio/Video to Text Conversion Services",
          desc: "Transcribing audio or video content into text format, enabling businesses to archive, search, and analyze spoken content. This service is essential for industries like legal, media, and education."
        },
        {
          title: "Paper to CAD Conversion Services",
          desc: "Converting paper-based drawings or blueprints into CAD formats for architecture, engineering, and manufacturing companies. This service allows for accurate digital representation of physical designs."
        },
        {
          title: "Structured to Unstructured Data Conversion Services",
          desc: "Transforming structured data (e.g., databases, spreadsheets) into unstructured formats like text, emails, and documents for use in various business processes."
        },
        {
          title: "Multilingual Data Conversion Services",
          desc: "Converting and localizing data across different languages to suit international markets. This service helps businesses expand globally and maintain data consistency across regions."
        },
        {
          title: "Hard Copy to Digital Data Conversion Services",
          desc: "Converting hard copy documents into digital formats like PDFs or Word documents, often through scanning and OCR. This service is crucial for organizations digitizing large archives of paper documents."
        }
      ];
      
    

  return (
    <div>
      <div className='sticky-top'>
        <HeaderCommon/>
      </div>
        <section className="d-flex justify-content-center align-items-center">
            <div className="container">
                <h2 className="text-center display-4 fw-bold mt-5 mb-0 text-primary lilita-one-regular">Data Conversion Services</h2>
                <p className="text-center my-3 lead fs-5">
                    Our data conversion services are designed to transform your data into accessible, organized formats that meet your business needs. From converting PDFs to editable documents, to migrating legacy data into modern platforms, we ensure precision and efficiency at every step. Streamline your data management and unlock new possibilities with our comprehensive data conversion solutions. We offer;
                </p>


                <div className='d-flex flex-wrap justify-content-center'>
                {
                    DataConversionServices.map((mr, i)=>{
                        return(
                            <dl className='col-11 col-sm-10 col-md-5 col-lg-4 col-xl-3 m-2 py-2 px-4' key={i} style={{background: `${i%2===0?'#f0f0fd':'#e3e3fc'}`}}>
                                <dt className='text-center my-3 fs-5 lilita-one-regular text-primary'>{i+1}) {mr.title.toUpperCase()}</dt>
                                <dd style={{textAlign:'justify'}}>{mr.desc}</dd>
                            </dl>
                        )
                    })
                }
                </div>
            </div>
        </section>

    </div>
  )
}

export default DataConversion
