import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../assests/LOGO WO BACKGROUND.png'
import '../css/App.css'

const HeaderTransparent = () => {


  return (
    <div className='d-flex justify-content-between p-2 bg-primary'>
        <div className='lg-nav-logo nav-logo' style={{width:'350px'}} onClick={()=>window.location.href="/"}>
            <img className='me-2' src={Logo} style={{width: '50px'}} alt='Logo'/>
            <h1 className='text-white fs-2 m-0'>One Point Research</h1>
        </div>
        <div className='sm-nav-logo nav-logo' onClick={()=>window.location.href="/"}>
            <img className='me-2' src={Logo} style={{width: '50px'}} alt='Logo'/>
            <h1 className='text-white logo-text m-0'>One Point Research</h1>
        </div>
        <ul className='custom-nav-links m-0 p-2 fw-bold' style={{listStyleType:'none'}}>
            <li className='p-2'>
                <Link to='/' className='text-light lead' style={{textDecoration:'none'}} >HOME</Link>
            </li>
            <li className='p-2'>
                <Link to='/services' className='text-light lead' style={{textDecoration:'none'}} >SERVICES</Link>
            </li>
            <li className='p-2'>
                <Link to='/about' className='text-light lead' style={{textDecoration:'none'}} >ABOUT US</Link>
            </li>
            <li className='p-2'>
                <Link to='/contact' className='text-light lead' style={{textDecoration:'none'}} >CONTACT</Link>
            </li>
        </ul>
        <div className='lg-nav-content custom-cta' style={{width:'350px'}}>
            <button className='btn btn-light px-4 py-1 fs-5 me-2' onClick={()=>window.location.href="/contact"}>Enquire</button>
        </div>
        <div className='sm-nav-content'>
            <button className="btn btn-outline-light px-2 py-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><i className='bi bi-list fs-1'></i></button>

            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{width:'100%'}}>
                <div className="offcanvas-header">
                <div className='sm-nav-logo nav-logo' onClick={()=>window.location.href="/"}>
                    <img className='me-2' src={Logo} style={{width: '50px'}} alt='Logo'/>
                    <h1 className='text-primary logo-text m-0'>One Point Research</h1>
                </div>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body text-center">
                    <span className='my-3 fw-bold fs-5'>PAGES</span>
                    <div className="list-group mt-3">
                        <a href="/" className="list-group-item list-group-item-action" aria-current="true">
                            Home
                        </a>
                        <a href="/services" className="list-group-item list-group-item-action">Services</a>
                        <a href="/about" className="list-group-item list-group-item-action">About Us</a>
                        <a href="/contact" className="list-group-item list-group-item-action">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HeaderTransparent
