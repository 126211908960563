import React from 'react'
import HeaderCommon from './HeaderCommon'

const UiUxDesigning = () => {

    const uiuxDesigning = [
        {
            title: 'Mobile Applications',
            desc: 'Design intuitive and engaging mobile applications that provide seamless user experiences and drive user retention.'
        },
        {
            title: 'Website Design',
            desc: 'Craft responsive and visually appealing websites that offer an exceptional user experience, ensuring high engagement and conversions.'
        },
        {
            title: 'Mockup Design',
            desc: 'Visualize your ideas with detailed mockups that provide a clear roadmap for development, ensuring the final product meets user expectations.'
        },
        {
            title: 'Poster/Banner Design',
            desc: 'Create impactful posters and banners that capture attention, communicate your message effectively, and enhance your brand visibility.'
        },
        {
            title: 'Landing Pages',
            desc: 'Design conversion-focused landing pages that guide visitors towards your desired actions, improving lead generation and sales.'
        }
    ];
    

  return (
    <div>
      <div className='sticky-top'>
        <HeaderCommon/>
      </div>
        <section className="d-flex justify-content-center align-items-center">
            <div className="container">
                <h2 className="text-center display-4 fw-bold mt-5 mb-0 text-primary lilita-one-regular">UI/UX Designing</h2>
                <p className="text-center my-3 lead fs-5">Our design solutions focus on creating engaging and intuitive user experiences. We blend creativity with functionality to design interfaces that not only look great but also enhance user satisfaction and drive interaction. We do;</p>
                <div className='d-flex flex-wrap justify-content-center'>
                {
                    uiuxDesigning.map((mr, i)=>{
                        return(
                            <dl className='col-11 col-sm-10 col-md-5 col-lg-4 col-xl-3 m-2 py-2 px-4' key={i} style={{background: `${i%2===0?'#f0f0fd':'#e3e3fc'}`}}>
                                <dt className='text-center my-3 fs-5 lilita-one-regular text-primary'>{i+1}) {mr.title.toUpperCase()}</dt>
                                <dd style={{textAlign:'justify'}}>{mr.desc}</dd>
                            </dl>
                        )
                    })
                }
                </div>
            </div>
        </section>
    </div>
  )
}

export default UiUxDesigning
