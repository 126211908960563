import React from 'react'
import HeaderCommon from './HeaderCommon'

const NotFound = () => {
  return (
    <div>
        <HeaderCommon/>
        <div className='d-flex justify-content-center align-items-center' style={{minHeight:'75vh'}}>
            <div className='text-center'>
                <span className='display-1 fw-bolder mt-5'><span className='text-primary'>404</span> Not found!</span>
                <p className='fs-5'>The page you are searching for isn't available right now</p>
                <button className='btn btn-primary fs-5' onClick={()=>window.location.href="/"}>Go to Homepage</button>
            </div>
        </div>
    </div>
  )
}

export default NotFound
