import { Routes, Route } from 'react-router-dom';
import './css/App.css';
import Footer from './components/Footer';
import Home from './components/Home';
import Services from './components/Services';
import Aboutus from './components/Aboutus';
import Contact from './components/Contact';
import MarketResearch from './components/MarketResearch';
import DataEntry from './components/DataEntry';
import Digitalerketing from './components/Digitalerketing';
import UiUxDesigning from './components/UiUxDesigning';
import Transcription from './components/Transcription';
import DataConversion from './components/DataConversion';
import DataProcessing from './components/DataProcessing';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import NotFound from './components/NotFound';

function App() {
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='p-0 col-12'>
          <Routes>
            <Route path='/' element={<Home/>} />
            <Route path='/services' element={<Services/>} />
            <Route path='/about' element={<Aboutus/>} />
            <Route path='/contact' element={<Contact/>} />
            <Route path='/market-research' element={<MarketResearch/>} />
            <Route path='/data-entry' element={<DataEntry/>} />
            <Route path='/digital-marketing' element={<Digitalerketing/>} />
            <Route path='/ui-ux-desinging' element={<UiUxDesigning/>} />
            <Route path='/transcription' element={<Transcription/>} />
            <Route path='/data-conversion' element={<DataConversion/>} />
            <Route path='/data-processing' element={<DataProcessing/>} />
            <Route path='/privacy-policy' element={<Privacy/>} />
            <Route path='/terms-condition' element={<Terms/>} />
            <Route path='*' element={<NotFound/>} />
          </Routes>
          <Footer/>
        </div>
      </div>
    </div>
  );
}

export default App;
