import React from 'react'
import MRImg from '../assests/MrImg.jpeg'
import DEImg from '../assests/DataEntryImg.svg'
import DMImg from '../assests/DMImg.jpeg'
import UIUXImg from '../assests/UiUxDesigningImg.svg'
import TImg from '../assests/transcription.jpg'
import DcImg from '../assests/data-conversion.jpg'
import DpImg from '../assests/data-process.jpg'


const ServiceCards = () => {

    const Service = [
        {
          title: 'Web/ Market Research',
          desc: `Gain deep insights into market trends, consumer behavior, and competitive analysis.`,
          icon: 'bi-bar-chart-steps',
          img: MRImg,
          // point: ['Firmographic Data','Demographic Data','Chronographic Data','Customer Data'],
          point: ['Exploratory Research','Descriptive Research','Causal Research','Predictive Research'],
          link: '/market-research'
        },
        {
          title: 'Data Entry',
          desc: `Ensuring accuracy and efficiency in managing your data.`,
          icon: 'bi-kanban',
          img: DEImg,
          point: ['Product Data Entry Services','Invoice Data Entry Services','Medical Data Entry Services','Data Entry from Paper Documents'],
          link: '/data-entry'
        },
        {
          title: 'Digital Marketing',
          desc: `Ensuring accuracy and efficiency in managing your data.`,
          icon: 'bi-bar-chart-fill',
          img: DMImg,
          point: ['Website Design','SEO, Google Ads','Social Media Marketing', 'Email Marketing Services'],
          link: '/digital-marketing'
        },
        {
          title: 'UI/ UX Design',
          desc: `Creating intuitive and engaging user experiences that drive interaction.`,
          icon: 'bi-palette',
          img: UIUXImg,
          point: ['Mobile Applications', 'Website Design','Poster/Banner Design','Landing Pages'],
          link: '/ui-ux-desinging'
        },
        {
          title: 'Transcription Services',
          desc: `Creating intuitive and engaging user experiences that drive interaction.`,
          icon: 'bi-palette',
          img: TImg,
          point: ['General Transcription Services', 'Medical Transcription Services','Legal Transcription Services','Academic Transcription Services'],
          link: '/transcription'
        },
        {
          title: 'Data Conversion Services',
          desc: `Creating intuitive and engaging user experiences that drive interaction.`,
          icon: 'bi-palette',
          img: DcImg,
          point: ['PDF to Word Conversion Services', 'Image to Text (OCR) Conversion Services','Excel to Database Conversion Services','HTML to XML/JSON Conversion Services'],
          link: '/data-conversion'
        },
        {
          title: 'Data Processing Services',
          desc: `Creating intuitive and engaging user experiences that drive interaction.`,
          icon: 'bi-palette',
          img: DpImg,
          point: ['Data Cleansing and Enrichment Services', 'Data Mining Outsourcing','Data Conversion Outsourcing','Database Management Services'],
          link: '/data-processing'
        }
    ]

  return (
    <div className="row d-flex flex-wrap justify-content-center">
            
        {
        Service.map((service, i)=>{
            return(
            <div className='service-card p-0 rounded bg-light m-2 col-11 col-sm-11 col-md-5 col-lg-25' key={i} onClick={()=>window.location.href=`${service.link}`} role='button'>
                <img className='w-100 rounded-top' style={{objectFit:'cover'}} src={service.img} alt={service.title}/>
                <div className='py-3'>
                    <h2 className='text-center text-primary fs-4 fw-bold'>{service.title.toUpperCase()}</h2>
                    <div className='d-flex justify-content-center'>
                    <ul>
                        {
                        service.point.map((point, i)=>{
                            return(
                            <li className='' key={i}>{point}</li>
                            )
                        })
                        }
                    </ul>
                    </div>
                    <div className='d-flex justify-content-center'>
                    <button className='btn btn-outline-primary fs-5 px-4 rounded-pill' onClick={()=>window.location.href=`${service.link}`}>View</button>
                    </div>
                </div>
            </div>
            )
        })
        }
      
    </div>
  )
}

export default ServiceCards
