import React, { useState } from 'react'
import HeaderCommon from './HeaderCommon'
import Emailjs from '@emailjs/browser'

const Contact = () => {

  const serviceId = process.env.REACT_APP_EmailJs_ServiceId
  const templateId = process.env.REACT_APP_EmailJs_TemplateId
  const publicId = process.env.REACT_APP_EmailJs_PublicId
  

  const [fullname, setFullname] = useState('')
  const [email, setEmail] = useState('')
  const [contact, setContact] = useState('')
  const [city, setCity] = useState('')
  const [company, setCompany] = useState('')
  const [subject, setSubject] = useState('')
  const [desc, setDesc] = useState('')

  const handleSubmit = (e) =>{
    e.preventDefault()
    const form = e.currentTarget
    form.classList.add('was-validated')
    
    if(form){
      if(form.checkValidity() && fullname!=='' && email!=='' && contact!=='' && city!=='' && subject!==''){

        const templateData = { fullname, email, contact, city, company, subject, desc }
        if(templateData){
          Emailjs.send(serviceId, templateId, templateData, publicId)
            .then(
              (response) => {
                alert('Form submitted successfully!');
                form.reset();
                form.classList.remove('was-validated'); // Reset validation state
              },
              (error) => {
                console.error('Email sending failed:', error);
                alert('Error sending email. Please try again later.');
              }
            );
        }

      }
      else{
        alert('Please fill necessary detials!')
      }
    }
    
  }

  return (
    <div className="contact-page">
      <div className='sticky-top'>
        <HeaderCommon/>
      </div>
      {/* Hero Section */}
      <section className="text-center d-flex justify-content-center align-items-center">
        <div className="container">
          <h1 className="display-4 fw-bold mt-5 mb-0 text-primary lilita-one-regular">CONTACT US</h1>
          <p className="lead fs-4">We'd love to hear from you! Get in touch with us using the form below.</p>
        </div>
      </section>

      {/* Contact Form Section */}
      <section className="contact-form-section pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2 className="text-primary mt-5 mb-4">Get in Touch</h2>
              <form className='needs-validation' noValidate onSubmit={(e)=>handleSubmit(e)}>
                <div className="form-group">
                  <label htmlFor="fullname">Full Name</label>
                  <input type="text" id="fullname" value={fullname} onChange={(e)=>setFullname(e.target.value)} className="form-control" placeholder="Full Name" required />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input type="email" id="email" value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control" placeholder="name@mail.exe" required />
                </div>
                <div className="form-group">
                  <label htmlFor="contact">Contact Number</label>
                  <input type="tel" id="contact" value={contact} onChange={(e)=>setContact(e.target.value)} className="form-control" placeholder="+1234567890" required />
                </div>
                <div className="form-group">
                  <label htmlFor="city">City</label>
                  <input type="text" id="city" value={city} onChange={(e)=>setCity(e.target.value)} className="form-control" placeholder="City" required />
                </div>
                <div className="form-group">
                  <label htmlFor="company">Company (Optional)</label>
                  <input type="text" id="company" value={company} onChange={(e)=>setCompany(e.target.value)} className="form-control" placeholder="Company" />
                </div>
                <div className="form-group">
                  <label htmlFor="subject">Subject</label>
                  <input type="text" id="subject" value={subject} onChange={(e)=>setSubject(e.target.value)} className="form-control" placeholder="Subject" required />
                </div>
                <div className="form-group">
                  <label htmlFor="description">Description (Optional)</label>
                  <textarea id="description" value={desc} onChange={(e)=>setDesc(e.target.value)} className="form-control" rows="4" placeholder="Describe your inquiry or request"></textarea>
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
              </form>
            </div>
            <div className="col-md-6">
              <h2 className="text-primary mt-5 mb-4">Our Location</h2>
              <div className="map-container">
                {/* Embed Google Maps */}
                <iframe
                  title="Google Maps Location"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1861.7474371026576!2d77.02445611978561!3d11.00931939557577!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba857768b2785ed%3A0x99b3c620d6e0283c!2sKamarajar%20Rd%2C%20Coimbatore%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1723775697317!5m2!1sen!2sin"
                  width="100%"
                  height="300"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>
              <div className="contact-info mt-4">
                <h3 className="text-primary">Contact Information</h3>
                <p className='m-0 d-flex align-items-center text-dark'><i className="bi bi-geo-alt fs-2"></i> Kamarajar Road, Coimbatore, Tamil Nadu - 641015</p>
                <p className='m-0 d-flex align-items-center text-dark' onClick={()=>window.open("tel:+919629539137")} role='button'><i className="bi bi-phone fs-2"></i> +91 96295 39137</p>
                <p className='m-0 d-flex align-items-center text-dark' onClick={()=>window.open("mailto:onepointresearch.site@gmail.com")} role='button'><i className="bi bi-envelope fs-2"></i> onepointresearch.site@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Contact
