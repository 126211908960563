import React from 'react'

const Footer = () => {
  return (
    <footer className="footer text-light border-top border-secondary py-3">
      <div className="container text-center">
        <div className="social-icons">
          <a href="https://wa.me/919629539137" className="text-success mx-2" aria-label="Twitter" target="_blank" rel="noopener noreferrer">
            <i className="bi bi-whatsapp fs-1"></i>
          </a>
          <a href="https://www.linkedin.com/company/one-point-research/about/" className="mx-2" style={{color: '#0A66C2'}} aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
            <i className="bi bi-linkedin fs-1"></i>
          </a>
        </div>
        <div className='d-flex justify-content-center flex-wrap gap-2'>
          <a href='/terms-condition' className='text-light' style={{textDecoration: 'none'}}><u>Terms & Conditions</u></a><a href='/privacy-policy' className='text-light' style={{textDecoration: 'none'}}><u>Privacy & Policy</u></a>
        </div>
        <p className="mb-2">&copy; 2024 One Point Research. All rights reserved.</p>
      </div>
    </footer>
  )
}

export default Footer
