import React from 'react'
import HeaderCommon from './HeaderCommon'

const MarketResearch = () => {

    // const marketResearch = [
    //     {
    //         title: 'Firmographic data',
    //         desc: `Firmographic data provides detailed insights into businesses, including company size, industry, revenue, location, and operational status. It’s essential for B2B marketing, sales strategies, and market segmentation, enabling precise targeting and decision-making.`
    //     },
    //     {
    //         title: 'Demographic Data',
    //         desc: `Demographic data provides detailed information about the characteristics of a population, such as age, gender, income, education, and occupation. It is essential for understanding target audiences and making informed marketing, business, and policy decisions.`
    //     },
    //     {
    //         title: 'Chronographic Data',
    //         desc: `Chronographic data analyzes temporal patterns, tracking changes over time to understand trends and predict future behaviors. It provides insights into how timing impacts consumer actions, market dynamics, and business performance.`
    //     },
    //     {
    //         title: 'Customer data',
    //         desc: `Customer data refers to any information collected about individuals who interact with a business. This data includes demographic details, purchase history, preferences, and behaviors, enabling companies to personalize experiences, improve services, and make data-driven decisions.`
    //     },
    //     {
    //         title: 'Data Validating',
    //         desc: `Ensure your data's  accuracy reliability through rigorous validation processes. Benifit from enhanced  data quality resucee errors and increased trust in data.`
    //     },
    //     {
    //         title: `Data Cleansing`,
    //         desc: `Enhance your data quality by removing inaccuracies, duplicate, and outdated information. Benifit from reliable data,better decision making,and increased efficiency.`
    //     }
    // ]

    // const marketResearchTypes = [
    //     {
    //         title: 'Exploratory Research',
    //         desc: `Exploratory research is conducted to explore a problem or opportunity that is not well defined. It aims to gain insights, discover new ideas, and identify potential areas for further investigation. Common methods include interviews, focus groups, and literature reviews.`
    //     },
    //     {
    //         title: 'Descriptive Research',
    //         desc: `Descriptive research focuses on describing the characteristics of a population or phenomenon being studied. It provides a clear picture of the current state of the market, helping businesses understand trends and patterns. Methods include surveys, observations, and case studies.`
    //     },
    //     {
    //         title: 'Causal Research',
    //         desc: `Causal research is used to understand the cause-and-effect relationships between variables. It helps in determining the impact of one variable on another, allowing businesses to predict outcomes and make strategic decisions. Methods include experiments, test markets, and simulations.`
    //     },
    //     {
    //         title: 'Predictive Research',
    //         desc: `Predictive research aims to forecast future trends and behaviors based on existing data. It helps businesses anticipate market changes and consumer responses, enabling them to plan and strategize effectively. Common methods include statistical analysis, trend analysis, and machine learning models.`
    //     },
    //     {
    //         title: 'Applied Research',
    //         desc: `Applied research is conducted to solve specific problems or answer specific questions within an organization. It focuses on practical applications and uses various research methods depending on the nature of the problem.`
    //     },
    //     {
    //         title: 'Basic Research',
    //         desc: `Basic research is aimed at expanding knowledge about a subject without an immediate practical application in mind. It is often theoretical and is conducted to increase understanding of fundamental concepts. Methods include literature reviews, theoretical research, and fundamental data analysis.`
    //     }
    // ];
    

    const marketResearchTypes = [
        {
            title: 'Company Data',
            desc: `We offer comprehensive company data services, including business profiles, industry classifications, financials, growth metrics, and organizational structures. This data helps in understanding market competitors and identifying potential business partners.`
        },
        {
            title: 'Contact Data',
            desc: `Our contact data services provide accurate and updated contact information for key decision-makers, including emails, phone numbers, and addresses. This ensures effective B2B outreach and improved lead generation and sales outreach.`
        },
        {
            title: 'Equity Data',
            desc: `We deliver detailed equity data, including financial statements, stock performance, and investment information. This data is crucial for analyzing company valuations and making informed investment decisions.`
        },
        {
            title: 'Data Append',
            desc: `Enhance your existing data with our data append services, adding missing information such as addresses, phone numbers, and emails to enrich your customer profiles and improve data accuracy.`
        },
        {
            title: 'B2B Audience Data',
            desc: `Access high-quality B2B audience data, including industry-specific leads and business contacts, to boost your sales efforts and optimize your marketing strategies.`
        },
        {
            title: 'Web Crawling',
            desc: `Our web crawling services systematically browse and index web pages to gather data, providing you with up-to-date and relevant information from across the internet for market analysis.`
        },
        {
            title: 'Web Scraping',
            desc: `We specialize in web scraping to extract and collect specific data from websites, transforming unstructured web content into structured data for actionable insights and competitive analysis.`
        }
    ];
    


  return (
    <div>
      <div className='sticky-top'>
        <HeaderCommon/>
      </div>
        <section className="text-center d-flex justify-content-center align-items-center">
            <div className="container">
                <h2 className="display-4 fw-bold mt-5 mb-0 text-primary lilita-one-regular">Market Research</h2>
                <p className="lead fs-5">We helps businesses understand consumer needs and preferences, market trends, and the competitive landscape, enabling them to make informed decisions regarding product development, marketing strategies, and business growth.</p>
            </div>
      </section>
      <div className='d-flex flex-wrap justify-content-center'>
        {
            marketResearchTypes.map((mr, i)=>{
                return(
                    <dl className='col-11 col-sm-10 col-md-5 col-lg-4 col-xl-3 m-2 py-2 px-4' key={i} style={{background: `${i%2===0?'#f0f0fd':'#e3e3fc'}`}}>
                        <dt className='text-center my-3 fs-5 lilita-one-regular text-primary'>{i+1}) {mr.title.toUpperCase()}</dt>
                        <dd style={{textAlign:'justify'}}>{mr.desc}</dd>
                    </dl>
                )
            })
        }
      </div>
    </div>
  )
}

export default MarketResearch
