import React from 'react'
import HeaderCommon from './HeaderCommon'

function Privacy() {
  return (
    <div className="container-fluid">
      <HeaderCommon/>
      <div className="card shadow p-4 my-5">
        <dl>
          <dt className="text-center text-primary mb-4"><h2>Privacy & Policy</h2></dt>
          <dd>
            <p><strong>Effective Date:</strong> 01 December 2024</p>
            <p>
              ONE POINT RESEARCH, headquartered in Coimbatore, Tamil Nadu, India, is committed to protecting your
              privacy. This policy outlines how we collect, use, and safeguard your data.
            </p>
            <ol className="list-group list-group-numbered">
              <li className="list-group-item">
                <strong>Information We Collect:</strong> We collect personal data such as names, contact details, and
                business information necessary for service delivery.
              </li>
              <li className="list-group-item">
                <strong>Purpose of Data Collection:</strong> Data is collected to enhance service delivery, improve
                user experience, and conduct research.
              </li>
              <li className="list-group-item">
                <strong>Data Sharing:</strong> We do not share your data with third parties without explicit consent
                unless required by law.
              </li>
              <li className="list-group-item">
                <strong>Data Storage:</strong> All data is securely stored and encrypted to prevent unauthorized access.
              </li>
              <li className="list-group-item">
                <strong>Retention Policy:</strong> Data is retained only as long as necessary for the purposes outlined
                in this policy.
              </li>
              <li className="list-group-item">
                <strong>Security Measures:</strong> We use advanced technology to protect data from breaches,
                unauthorized access, and misuse.
              </li>
              <li className="list-group-item">
                <strong>Your Rights:</strong> You have the right to request access, corrections, or deletion of your
                personal data.
              </li>
              <li className="list-group-item">
                <strong>Cookies:</strong> Our website uses cookies to enhance your browsing experience. You may disable
                cookies in your browser settings.
              </li>
              <li className="list-group-item">
                <strong>Third-Party Services:</strong> We may use third-party services to support our operations, all of
                which comply with confidentiality standards.
              </li>
              <li className="list-group-item">
                <strong>Minors:</strong> Our services are not directed at individuals under 18 without parental consent.
              </li>
              <li className="list-group-item">
                <strong>Updates to Policy:</strong> We reserve the right to update this Privacy Policy and will notify
                users of significant changes.
              </li>
              <li className="list-group-item">
                <strong>Compliance with Laws:</strong> We comply with all applicable data protection regulations in
                India.
              </li>
              <li className="list-group-item">
                <strong>Third-Party Websites:</strong> Our website may contain links to external sites, for which we are
                not responsible.
              </li>
              <li className="list-group-item">
                <strong>Use of Data for Marketing:</strong> With explicit consent, we may use data to send marketing
                communications.
              </li>
              <li className="list-group-item">
                <strong>International Data Transfers:</strong> Data may be processed in locations outside India, subject
                to applicable laws.
              </li>
              <li className="list-group-item">
                <strong>Consent:</strong> By using our services, you consent to this Privacy Policy.
              </li>
              <li className="list-group-item">
                <strong>Data Breaches:</strong> In the event of a data breach, affected parties will be promptly
                notified.
              </li>
              <li className="list-group-item">
                <strong>Opt-Out:</strong> You can opt-out of data collection or marketing communications at any time.
              </li>
              <li className="list-group-item">
                <strong>Contact Details:</strong> For questions, contact us at:
                <ul className="mt-2">
                  <li><strong>Phone:</strong> +91 96295 39137</li>
                  <li><strong>Email:</strong> onepointresearch.site@gmail.com</li>
                </ul>
              </li>
              <li className="list-group-item">
                <strong>Governing Law:</strong> This Privacy Policy is governed by the laws of India and subject to the
                jurisdiction of courts in Coimbatore, Tamil Nadu.
              </li>
            </ol>
          </dd>
        </dl>
      </div>
    </div>
  )
}

export default Privacy
