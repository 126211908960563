import React from 'react'
import { Link } from 'react-router-dom'
import MRImg from '../assests/marketResearch.svg'
import DEImg from '../assests/dataEntry.svg'
import DMImg from '../assests/digitalMarketing.svg'

const Carousel = () => {

    // const carouselContent = [
    //     {
    //         title: 'EMPOWERING YOUR BUSINESS WITH DATA-DRIVEN RESEARCH AND MARKET INSIGHTS',
    //         desc: `Unlock your business potential with our comprehensive market research services. We delve deep into market trends, analyze key data, and provide actionable insights to help you succeed in a competitive landscape. Research, analyze, and watch your business thrive.`,
    //         img: MRImg
    //     },
    //     {
    //         title: 'DRIVE EFFICIENCY WITH ACCURATE AND RELIABLE DATA ENTRY SERVICES',
    //         desc: `Transform your data management with our precise and efficient data entry services. Whether it's digitizing paper documents or managing large datasets, our expert team ensures accuracy and reliability, enabling you to focus on what matters most-growing your business. Experience seamless data management with our customized solutions.`,
    //         img: DEImg
    //     },
    //     {
    //         title: 'ELEVATE YOUR BRAND WITH INNOVATIVE UI/UX DESIGN AND DIGITAL MARKETING',
    //         desc: `Stand out in the digital world with our cutting-edge UI/UX design and strategic digital marketing services. We craft visually appealing designs and engaging user experiences that resonate with your audience, while our targeted marketing strategies drive traffic, boost conversions, and grow your brand. Take your online presence to the next level.`,
    //         img: DMImg
    //     }
    // ]

    const carouselContent = [
        {
            title: 'EMPOWER YOUR BUSINESS WITH DATA-DRIVEN INSIGHTS',
            desc: `Unlock your potential with comprehensive research. We analyze trends, deliver key insights, and help you succeed in a competitive landscape. Research, analyze, and thrive.`,
            img: MRImg
        },
        {
            title: 'BOOST EFFICIENCY WITH RELIABLE DATA ENTRY SERVICES',
            desc: `Transform data management with our precise services. From digitizing documents to handling large datasets, we ensure accuracy, letting you focus on growth.`,
            img: DEImg
        },
        {
            title: 'ELEVATE YOUR BRAND WITH UI/UX AND DIGITAL MARKETING',
            desc: `Stand out online with innovative design and marketing. We create engaging user experiences and strategies that drive traffic, conversions, and growth.`,
            img: DMImg
        }
    ];
    

  return (
    <section>
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div className="carousel-inner">
                {
                    carouselContent.map((data, i)=>{
                        return(
                            // <div className="carousel-item active">
                            //     <img src="..." className="d-block w-100" alt="..." />
                            //     <div className="carousel-caption d-none d-md-block">
                            //         <h5>{data.title}</h5>
                            //         <p>{data.desc}</p>
                            //     </div>
                            // </div>

                            <div className={`carousel-item ${i===0&&'active'} landing-content bg-primary`} data-bs-interval="5000" key={i}>
                                {/* Hero Section */}
                                <div className="hero-section d-flex align-items-center justify-content-center text-center">
                                <div className="hero-content col-11 col-sm-10 col-lg-10 col-xl-8">
                                    <div>
                                        <img className='my-3 carousel-img' src={data.img} alt={data.title}/>
                                        <div>
                                            <h1 className='fs-1 text-white lilita-one-regular landing-content'>{data.title}</h1>
                                            <p className='text-light mx-3 my-3 landing-content fs-5' style={{textAlign:'justify'}}>{data.desc}</p>
                                        </div>
                                    </div>
                                    <Link to="/services" className="btn btn-outline-light my-2 border-2 hero-content-btn px-4 py-2 fs-4 landing-btn">Our Services</Link>
                                </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    </section>
  )
}

export default Carousel
