import React from 'react'
import HeaderCommon from './HeaderCommon'

const DataProcessing = () => {


    const DataProcessingService = [
        {
          "title": "Data Cleansing and Enrichment Services",
          "desc": "Data cleansing removes inaccurate, incomplete, or irrelevant data, while data enrichment enhances the quality by adding relevant information. These processes ensure that your datasets are clean, accurate, and useful for decision-making.",
          "seoKeywords": ["Data cleansing services", "data enrichment solutions", "clean data", "accurate data processing", "data validation"]
        },
        {
          "title": "Data Mining Outsourcing",
          "desc": "Data mining involves extracting useful patterns and trends from large datasets. Outsourcing data mining services enables businesses to gain actionable insights for decision-making, customer segmentation, and market research.",
          "seoKeywords": ["Data mining services", "outsource data mining", "data analysis outsourcing", "big data mining", "predictive analytics"]
        },
        {
          "title": "Data Conversion Outsourcing",
          "desc": "Data conversion transforms data from one format to another, such as converting paper documents into digital formats or migrating data from outdated systems. This service ensures smooth digital transitions and data accessibility.",
          "seoKeywords": ["Data conversion services", "outsource data conversion", "digital data transformation", "document digitization", "data migration"]
        },
        {
          "title": "Database Management Services",
          "desc": "Outsourcing database management involves maintaining and optimizing database systems. This includes tasks like data storage, backup, and recovery to ensure that your data is always secure and easily accessible.",
          "seoKeywords": ["Database management outsourcing", "database services", "database optimization", "cloud database management", "secure data management"]
        },
        {
          "title": "Document Scanning and Indexing",
          "desc": "This service involves scanning physical documents and indexing them for easy retrieval and digital storage. It allows businesses to reduce physical storage costs and enhance document accessibility.",
          "seoKeywords": ["Document scanning services", "outsource document indexing", "digital document storage", "paperless solutions", "document digitization"]
        },
        {
          "title": "Data Validation and Verification",
          "desc": "This process ensures that data is accurate and consistent. Outsourcing data validation helps in cross-checking and confirming the authenticity of datasets, which is crucial for decision-making and maintaining data quality.",
          "seoKeywords": ["Data validation services", "outsource data verification", "accurate data validation", "quality data verification", "data accuracy"]
        },
        {
          "title": "Image Data Processing",
          "desc": "Image data processing involves tasks like image recognition, extraction, and annotation. Outsourcing this service helps businesses deal with large volumes of visual data, often used in fields like e-commerce, AI, and marketing.",
          "seoKeywords": ["Image data processing services", "outsource image data processing", "image recognition", "data annotation", "visual data processing"]
        },
        {
          "title": "Data Extraction Outsourcing",
          "desc": "Data extraction involves retrieving specific data from various unstructured or semi-structured sources like websites, databases, or forms. Outsourcing this task ensures timely and accurate data for research, analysis, or reporting.",
          "seoKeywords": ["Data extraction services", "outsource data extraction", "web scraping services", "structured data extraction", "automated data extraction"]
        },
        {
          "title": "Data Formatting and Standardization",
          "desc": "This service involves reformatting and standardizing data to ensure consistency across systems. Outsourcing data formatting helps maintain clean, organized datasets that meet industry standards.",
          "seoKeywords": ["Data formatting services", "outsource data standardization", "data normalization services", "clean data processing", "data organization"]
        },
        {
          "title": "Data Annotation and Labeling",
          "desc": "Data annotation and labeling involve adding metadata to datasets, often for machine learning or AI models. Outsourcing these services enables accurate data labeling, which is critical for training AI algorithms.",
          "seoKeywords": ["Data annotation services", "outsource data labeling", "AI data labeling", "machine learning data", "labeled datasets"]
        },
        {
          "title": "Real-Time Data Processing",
          "desc": "Real-time data processing ensures that data is collected, processed, and analyzed immediately. This is particularly useful for industries requiring instant feedback or action, such as finance or e-commerce.",
          "seoKeywords": ["Real-time data processing", "outsource real-time data services", "real-time analytics", "instant data processing", "real-time data insights"]
        },
        {
          "title": "Data Deduplication Services",
          "desc": "We offer comprehensive data deduplication services to eliminate redundant records and ensure data accuracy. By leveraging advanced algorithms, we optimize database performance, reduce storage costs, and enhance data consistency. Our deduplication solutions improve data integrity for better business decision-making.",
          "seoKeywords": ["Data deduplication services", "data deduplication", "remove duplicates", "database optimization", "data consistency"]
        },
        {
          "title": "Data Enhancement Services",
          "desc": "Our data enhancement services enrich and update your existing databases with relevant and accurate information. We add missing details, validate entries, and improve data quality to empower your marketing and sales efforts. Achieve targeted outreach and improved customer segmentation with up-to-date, reliable data.",
          "seoKeywords": ["Data enhancement services", "data enrichment", "update databases", "data quality improvement", "customer segmentation"]
        }
      ]
      
    

  return (
    <div>
      <div className='sticky-top'>
        <HeaderCommon/>
      </div>
        <section className="d-flex justify-content-center align-items-center">
            <div className="container">
                <h2 className="text-center display-4 fw-bold mt-5 mb-0 text-primary lilita-one-regular">Data Processing Services</h2>
                <p className="text-center my-3 lead fs-5">
                    Data processing services are essential for businesses to manage, analyze, and optimize their datasets effectively. From data cleansing to real-time data processing, these services help transform raw data into actionable insights, enabling better decision-making and streamlined business operations. Explore a variety of data processing services tailored to meet the unique needs of your organization.
                </p>

                <div className='d-flex flex-wrap justify-content-center'>
                {
                    DataProcessingService.map((mr, i)=>{
                        return(
                            <dl className='col-11 col-sm-10 col-md-5 col-lg-4 col-xl-3 m-2 py-2 px-4' key={i} style={{background: `${i%2===0?'#f0f0fd':'#e3e3fc'}`}}>
                                <dt className='text-center my-3 fs-5 lilita-one-regular text-primary'>{i+1}) {mr.title.toUpperCase()}</dt>
                                <dd style={{textAlign:'justify'}}>{mr.desc}</dd>
                            </dl>
                        )
                    })
                }
                </div>
            </div>
        </section>

    </div>
  )
}

export default DataProcessing
