import React from 'react'
import HeaderCommon from './HeaderCommon'
import ServiceCards from './ServiceCards'

const Services = () => {



  return (
    <div className="services-page">

      <div className='sticky-top'>
        <HeaderCommon/>
      </div>
      {/* Hero Section */}
      <section className="text-center d-flex justify-content-center align-items-center">
        <div className="container">
          <h1 className="display-4 fw-bold mt-5 mb-0 text-primary lilita-one-regular">OUR SERVICES</h1>
          <p className="lead fs-4">Discover how we can help elevate your business with our expert services.</p>
        </div>
      </section>

      {/* Services Section */}
      <section className="services-section pt-3 pb-5">
        <div className="container-fluid">

          <ServiceCards/>

        </div>
      </section>

      {/* Contact Section */}
      <section className="contact-section py-5 text-center bg-light">
        <div className="container">
          <h2 className="text-primary">Get in Touch</h2>
          <p>If you have any questions or want to know more about our services, feel free to <a href="/contact" className="btn btn-outline-primary px-4 rounded-pill fs-5 ms-3 my-3">Contact Us</a>.</p>
        </div>
      </section>
    </div>
  )
}

export default Services
