import React from 'react';
import HeaderCommon from './HeaderCommon';

function TermsAndConditions() {
  return (
    <div className="container-fluid">
      <HeaderCommon/>
      <div className="card shadow p-4 my-5">
        <h2 className="text-center mb-4 text-primary">Terms and Conditions</h2>
        <p><strong>Effective Date:</strong> 01 December 2024</p>
        <p>
          Welcome to ONE POINT RESEARCH, located in Coimbatore, Tamil Nadu, India. By using our services, you agree to
          these terms and conditions. Please read them carefully.
        </p>
        <ol className="list-group list-group-numbered">
          <li className="list-group-item">
            <strong>Acceptance of Terms:</strong> By engaging with ONE POINT RESEARCH, you agree to comply with these
            terms and any subsequent modifications.
          </li>
          <li className="list-group-item">
            <strong>Scope of Services:</strong> We provide services including data extraction, web scraping, data
            mining, market research, UI/UX design, digital marketing, and more.
          </li>
          <li className="list-group-item">
            <strong>Location:</strong> Our headquarters are located in Coimbatore, Tamil Nadu, India.
          </li>
          <li className="list-group-item">
            <strong>Service Customization:</strong> Each service is tailored to meet the unique needs of our clients, as
            defined in specific agreements.
          </li>
          <li className="list-group-item">
            <strong>Confidentiality:</strong> All client information and data are handled with the utmost
            confidentiality.
          </li>
          <li className="list-group-item">
            <strong>Intellectual Property Rights:</strong> All methodologies, content, and data provided by ONE POINT
            RESEARCH remain our intellectual property.
          </li>
          <li className="list-group-item">
            <strong>Prohibited Use:</strong> You agree not to misuse our services for illegal activities, including
            copyright infringement or data theft.
          </li>
          <li className="list-group-item">
            <strong>Payment Terms:</strong> Payments must be made in accordance with the agreed schedule. Delays may
            incur additional charges.
          </li>
          <li className="list-group-item">
            <strong>Accuracy of Information:</strong> While we strive to deliver accurate data, external variables may
            affect results.
          </li>
          <li className="list-group-item">
            <strong>Client Responsibilities:</strong> Clients must provide accurate details and maintain the
            confidentiality of all shared resources.
          </li>
          <li className="list-group-item">
            <strong>Data Security:</strong> We employ advanced security measures to protect all data exchanged during
            service delivery.
          </li>
          <li className="list-group-item">
            <strong>Force Majeure:</strong> We are not liable for delays caused by events beyond our control, such as
            natural disasters or technical disruptions.
          </li>
          <li className="list-group-item">
            <strong>Dispute Resolution:</strong> All disputes will be resolved under the jurisdiction of courts in
            Coimbatore, Tamil Nadu, India.
          </li>
          <li className="list-group-item">
            <strong>Termination:</strong> Either party may terminate the agreement with prior written notice.
          </li>
          <li className="list-group-item">
            <strong>Liability Limitation:</strong> Our liability is limited to the amount paid for the specific service
            in question.
          </li>
          <li className="list-group-item">
            <strong>Indemnification:</strong> You agree to indemnify us against any claims arising from misuse of our
            services.
          </li>
          <li className="list-group-item">
            <strong>Modification of Terms:</strong> We reserve the right to update these terms periodically. Clients
            will be notified of significant changes.
          </li>
          <li className="list-group-item">
            <strong>Governing Law:</strong> These terms are governed by the laws of India.
          </li>
          <li className="list-group-item">
            <strong>Contact Details:</strong> For inquiries, contact us at:
            <ul className="mt-2">
              <li><strong>Phone:</strong> +91 96295 39137</li>
              <li><strong>Email:</strong> onepointresearch.site@gmail.com</li>
            </ul>
          </li>
          <li className="list-group-item">
            <strong>Agreement Binding:</strong> By engaging with ONE POINT RESEARCH, you confirm acceptance of these
            terms.
          </li>
        </ol>
      </div>
    </div>
  );
}

export default TermsAndConditions;
