import React from 'react'
import HeaderCommon from './HeaderCommon'

const Digitalerketing = () => {

    const digitalMarketing = [
        {
            title: 'Website Design',
            desc: 'Create visually appealing and user-friendly websites that drive engagement, increase conversions, and elevate your online presence.'
        },
        {
            title: 'Add-on Services',
            desc: 'Enhance your website with customized add-ons that improve functionality, user experience, and performance.'
        },
        {
            title: 'SEO',
            desc: 'Boost your website’s visibility and ranking on search engines with strategic SEO practices that attract targeted traffic and generate leads.'
        },
        {
            title: 'Social Media Marketing',
            desc: 'Grow your brand’s online presence with effective social media strategies that engage your audience and drive conversions.'
        },
        {
            title: 'Google Ads',
            desc: 'Maximize your advertising ROI with targeted Google Ads campaigns that reach potential customers at the right moment.'
        },
        {
            title: 'Content Marketing',
            desc: 'Engage and inform your audience with high-quality content that builds brand authority, drives traffic, and increases conversions.'
        },
        {
            title: 'Email Marketing Services',
            desc: 'Nurture customer relationships and boost sales with personalized email marketing campaigns that deliver relevant content to your audience.'
        },
        {
            title: 'Conversion Optimization',
            desc: 'Increase your website’s effectiveness by optimizing user experience and calls-to-action, turning visitors into loyal customers.'
        }
    ];
    

  return (
    <div>
      <div className='sticky-top'>
        <HeaderCommon/>
      </div>
        <section className="d-flex justify-content-center align-items-center">
            <div className="container">
                <h2 className="text-center display-4 fw-bold mt-5 mb-0 text-primary lilita-one-regular">Digital Marketing</h2>
                <p className="text-center my-3 lead fs-5">We craft targeted digital marketing strategies that increase your online visibility and connect you with your ideal audience. From SEO to social media management, our approach is designed to boost your brand's presence and drive measurable results. We handle data entry with meticulous attention to detail, ensuring your information is organized, accurate, and ready for strategic use. We do;</p>
                <div className='d-flex flex-wrap justify-content-center'>
                {
                    digitalMarketing.map((mr, i)=>{
                        return(
                            <dl className='col-11 col-sm-10 col-md-5 col-lg-4 col-xl-3 m-2 py-2 px-4' key={i} style={{background: `${i%2===0?'#f0f0fd':'#e3e3fc'}`}}>
                                <dt className='text-center my-3 fs-5 lilita-one-regular text-primary'>{i+1}) {mr.title.toUpperCase()}</dt>
                                <dd style={{textAlign:'justify'}}>{mr.desc}</dd>
                            </dl>
                        )
                    })
                }
                </div>
            </div>
        </section>

    </div>
  )
}

export default Digitalerketing
